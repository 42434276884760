import rightArrow from "../../../assets/images/rightArrow.svg"
import searchIcon from "../../../assets/images/searchIcon.svg"
import userImage from "../../../assets/images/user.png"
import userImage2 from "../../../assets/images/user2.png"
import userImage3 from "../../../assets/images/user3.png"
import userImage4 from "../../../assets/images/user4.png"
import userImage5 from "../../../assets/images/user5.png"
import userImage6 from "../../../assets/images/user6.png"
import userImage7 from "../../../assets/images/user7.png"
import userImage8 from "../../../assets/images/user8.png"
import userImage9 from "../../../assets/images/user9.png"
import userImage10 from "../../../assets/images/user10.png"
import filter from "../../../assets/images/filter.svg"
import selectDropDown from "../../../assets/images/selectDropdown.svg"
import filterIcon from "../../../assets/images/filterBtn.svg"
import uploadIcon from "../../../assets/images/uploadBtn.svg"
import downloadIcon from "../../../assets/images/downloadBtn.svg"
import { useEffect, useState, useRef } from "react"
import APIList from "../../../api"
import { RiErrorWarningFill } from "react-icons/ri";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { useSelector, useDispatch } from "react-redux"
import { toast } from "react-toastify"
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import React from 'react';
import { saveAs } from 'file-saver';
import axios from "axios"
import { AUTHORISED_URL } from "../../../api/apiServices";
import { unparse } from 'papaparse';

const TalentNotes = () => {
    const history = useHistory();
    const employeeDetails = useSelector((state) => state?.empData?.empData);
    const userToken = useSelector((state) => state && state.user && state.user.userToken);
    const dispatch = useDispatch();
    const fileInputRef = useRef(null);
    const [talentDetailList, setTalentDetailList] = useState([]);
    const [talentNotesLoader, setTalentNotesLoaderLoader] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);

    useEffect(() => {
        gettalentDetailData()
    }, [])

    const gettalentDetailData = () => {
        setTalentNotesLoaderLoader(true);
        APIList.getTalentNotes({
            payload: {
                // "managerId": employeeDetails.id,
                "managerId": "4b6fa605-ba6c-41ed-8d35-6159cf78c6de",
                "annualYear": "Annual Review : 2019-20",
            },
        }).then((res) => {
            setTalentDetailList(res?.data);
            setTalentNotesLoaderLoader(false);
        }).catch((err) => {
            toast.error(
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                    &nbsp;&nbsp;{err?.title}
                </div>
            );
            setTalentNotesLoaderLoader(false);
        })
    }

    const onClickOfUploadData = () => {
        fileInputRef.current.click();
    }

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        setSelectedFile(event.target.files[0]);
        if (file) {
            setTalentNotesLoaderLoader(true);
            const formData = new FormData();
            formData.append('file', file);

            // const companyId =  employeeDetails.company.id,
            const companyId = "ba02f418-e44d-467c-9d5d-421a2d966460";
            APIList.uploadTalentData(formData, companyId).then((res) => {
                // setTalentDetailList(res?.data);
                gettalentDetailData()
                toast.success(<div className='flex flex-row'><BsFillCheckCircleFill style={{ width: "2rem", height: "2rem" }} />&nbsp;&nbsp;File Uploaded succesfully</div>);
                setTalentNotesLoaderLoader(false);
            }).catch((err) => {
                toast.error(
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                        &nbsp;&nbsp;{err?.title}
                    </div>
                );
                setTalentNotesLoaderLoader(false);
            })
        }
    };


    const handleDownloadReport = async () => {
        setTalentNotesLoaderLoader(true)
        console.log("userToken", userToken)
        try {
            const response = await axios.post(
                `${AUTHORISED_URL}talent-note/download?managerId=4b6fa605-ba6c-41ed-8d35-6159cf78c6de&annualYear=Annual Review : 2019-20`,
                null,
                {
                    headers: {
                        'Authorization': `Bearer ${userToken.id_token}`,
                        'Content-Type': 'application/json'
                    },
                    responseType: 'arraybuffer' // Specify the responseType here
                }
            );
            const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            saveAs(blob, 'talent-notes.csv');
            toast.success(<div className='flex flex-row'><BsFillCheckCircleFill style={{ width: "2rem", height: "2rem" }} />&nbsp;&nbsp;File Download succesfully</div>);
            setTalentNotesLoaderLoader(false);
        } catch (error) {
            toast.error(
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                    &nbsp;&nbsp;{error?.title}
                </div>
            );
            setTalentNotesLoaderLoader(false);
        }
    }

    const handleDownloadUploadFormat = () => {
        const data = [
            { 'EMP_ID': '', 'Moving The Function Forward': '', 'Comment': '', 'Tribal Knowledge': '', 'Potential': '', 'Crticality': '', 'Annual Year': '' }
        ];

        const headersToKeep = ['EMP_ID', 'Moving The Function Forward', 'Comment', 'Tribal Knowledge', 'Potential', 'Crticality', 'Annual Year'];
        const filteredData = data.map(item => {
            const filteredItem = {};
            headersToKeep.forEach(header => {
                filteredItem[header] = item[header] || ''; // Fill empty cells with empty string
            });
            return filteredItem;
        });

        const csv = unparse(filteredData, {
            header: true,
            skipEmptyLines: true
        });

        const blob = new Blob([csv], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'talnet format.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    return (
        <div className="talentNotes">
            <p className="header-title">Talent Notes</p>
            {/* <div className="breadcumb">
                <p className="mainPage">Team View</p>
                <img src={rightArrow} />
                <p className="subPage">Member</p>
            </div> */}
            <div className="talentNotes-inner">
                <div className="searchBar">
                    <div className="search">
                        <div className="form">
                            <label>Select Performance Year</label>
                            <div className="selectOption">
                                <select>
                                    <option>Annual : 2023-24</option>
                                    <option>Annual : 2023-24</option>
                                    <option>Annual : 2023-24</option>
                                    <option>Annual : 2023-24</option>
                                    <option>Annual : 2023-24</option>
                                </select>
                                <img src={selectDropDown} className="selectDD" />
                            </div>
                        </div>
                        <div className="btnOuter">
                            <button className="headerBtn filter">
                                <img src={filterIcon} className="selectDD" />
                                <p className="label">Filters</p>
                            </button>
                            {/* <input type="file" accept=".csv" onChange={handleFileChange} /> */}
                            <input
                                type="file"
                                accept=".csv"
                                ref={fileInputRef}
                                style={{ display: 'none' }}
                                onChange={handleFileChange}
                            />
                            <button onClick={() => handleDownloadReport()} className="headerBtn topBtn">
                                <img src={downloadIcon} className="selectDD" />
                                <p className="label">Download Data</p>
                            </button>
                            <button onClick={() => onClickOfUploadData()} className="headerBtn topBtn">
                                <img src={uploadIcon} className="selectDD" />
                                <p className="label">Upload Data</p>
                            </button>
                            <button onClick={() => handleDownloadUploadFormat()} className="headerBtn topBtn">
                                <img src={downloadIcon} className="selectDD" />
                                <p className="label">Download Upload Format</p>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="talentNotes-table">
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    <div className="filter">
                                        <p className="label">Name</p>
                                        <img src={filter} />
                                    </div>
                                </th>
                                <th>Tribal Knowledge</th>
                                <th>Criticality</th>
                                <th>Potential</th>
                                <th>Moving the Function Forward</th>
                                <th>Stakeholder Management</th>
                            </tr>
                        </thead>
                        <tbody>
                            {talentDetailList?.map((data, index) => (
                                <tr key={index}>
                                    <td>
                                        <div className="details">
                                            <img src={data?.employee.profilePhoto ? data?.employee.profilePhoto : userImage} className="userImage" />
                                            <div className="detailsInner">
                                                <p className="title">{data?.employee?.name}</p>
                                                <p className="empId">Emp id: {data?.employee?.employeeId}</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td>{data.tribalKnowledge}</td>
                                    <td>{data.criticality}</td>
                                    <td>{data.potential}</td>
                                    <td className="left">{data.movingTheFunctionForward}</td>
                                    <td className="left">{data.comment}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default TalentNotes;