import { type } from "@testing-library/user-event/dist/type";
import api from "./apiServices";
import { axiosInstanceCms } from "./apiServices";
import { axiosInstanceNotification } from "./apiServices";
import { axiosInstanceWorkFlow } from "./apiServices";
import { axiosInstanceAcc } from "./apiServices";
const workFlowApi = "http://dev-portal.benevolve.com/services/workflow/api"


const authenticateUser = (payload) => api.post(`/authenticate`, payload);
const logOutUser = (payload) => api.post(`/logout?token=${payload.jwtToken}`);

const getEmployeeData = () => api.get(`/employees/email`);
const getPerformanceData = ({ payload }) => {
  return api.get(`/new-employees/performance-history/${payload.employedId}`)
};
const getProfileinfoData = ({ payload }) => {
  return api.get(`/new-employees/employee-info/${payload.employedId}/${payload.companyId}`)
};
const getProfileData = ({ payload }) => {
  return api.get(`/new-employees/myTeam/${payload.employedId}/${payload.companyId}`)
};
const postProfileData = ({ payload }) => {
  return api.post(`/new-employees/myTeam/filter?page=0&size=10}`, payload)
};
const searchMyTeam = ({ payload }) => {
  return api.post(`/new-employees/employee-search`, payload)
};
// Getprofileinfromatin data
const getMyTeam = (payload) => api.post(`/new-employees/myTeam/filter?page=${payload?.page}&size=${payload?.size}`, payload?.payload);

const getcompetencyFeedback = ({employeeId, roleId}) => api.get(`/new-employees/performance-history/competency-report?employeeId=${employeeId}&roleId=${roleId}`)

const get_new_employees_filters = (payload) => api.get(`new-employees/filters/${payload}`)

const getTalentNotes = ({ payload }) => {
  return api.get(`/talent-note?managerId=${payload?.managerId}&annualYear=${payload?.annualYear}`)
};

const uploadTalentData = (payload, companyId) => {
  return api.post(`/newcsventity/upload/TalentNote/${companyId}/create?employeeId=EMP_ID&movingTheFunctionForward=Moving The Function Forward&comment=Comment&tribalKnowledge=Tribal Knowledge&potential=Potential&criticality=Crticality&annualYear=Annual Year`,
    payload,
  )
};

const downloadTalentData = ({ payload }) => {
  return api.post(`/talent-note/download?managerId=${payload?.managerId}&annualYear=${payload?.annualYear}`, payload)
};

const getSortedEmpList = (payload) => api.post(`/new-employees/myTeam/filter?page=${payload?.page}&size=${payload?.size}&sortBy=${payload?.sortBy}&sortDir=${payload?.sortDir}`, payload?.payload);

const APIList = {
  authenticateUser,
  logOutUser,
  getEmployeeData,
  getMyTeam,
  getPerformanceData,
  getProfileinfoData,
  getProfileData,
  postProfileData,
  searchMyTeam,
  get_new_employees_filters,
  getTalentNotes,
  uploadTalentData,
  downloadTalentData,
  getSortedEmpList,
  getcompetencyFeedback
};

export default APIList;
