import React  from 'react';
import { useDispatch, useSelector } from "react-redux";
import { actions as logInActions } from "../../../redux/login/actions";
import { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import ButtonM from "../Button";
import ReactDOM from 'react-dom';
import Popout from 'react-popout';
import { isEmptyNullUndefined } from "../../utils/utils";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import LoginBg from "../../../assets/images/loginImg.png"
import mediLogo from "../../../assets/images/mdiLogo/loginLogo.png"
import APIList from '../../../api';
import { toast } from 'react-toastify';
import { RiErrorWarningFill } from 'react-icons/ri';
import { CircularProgress, Skeleton } from '@mui/material';

const LogIn = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [submit, setSubmit] = useState(false);
  const [isToken, setIsToken] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [usernameText, setUsernameText] = useState(true);
  const [loginPopup, setLoginPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false)

  // const user = useSelector((state) => state && state.user && state.user.user);
  const userToken = useSelector((state) => state && state.user && state.user.userToken);
  const loading = useSelector((state) => state && state.user && state.user.loader);

  useEffect(() => {
   if(localStorage.getItem("loggedOutFromFooter")){ 
    localStorage.removeItem("loggedOutFromFooter");
    window.location.reload();
   }
  }, []);

  // logging in through admin portal 
 useEffect(() => {
    if (params?.xyz?.length > 0) {
      dispatch(logInActions.LogInRequest({
        username: "logInThroughPopupWindow",
        password: params?.xyz
      }))
    }
  }, [params]);

 useEffect(() => {
    // if (userToken && Object.keys(userToken).length > 0) {      
    if (userToken?.id_token !== undefined) {      
      // dispatch(logInActions.UserRequest());
      // history.push('/team-view');

      //////////// if there is isManager so route to view manager page/////
      const empData = () => {
        setIsLoading(() => true)
        APIList.getEmployeeData()
        .then((res) => {
            console.log('res', res)
            if(res.data.isManager == true) {
              history.push('/team-view');
            } else {
              history.push('/employee-profile/profile');
            }
        }).catch((err) => {
            toast.error(
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                    &nbsp;&nbsp;{err.message}
                </div>
            );
            history.push('/employee-profile/login')
        })
      }
      empData()
      
      // history.push('/employee-profile/profile'); //by default route to profile page
    }
  }, [userToken]);

  // useEffect(() => {
  //   if ((user && Object.keys(user).length > 0) && (user && !user.loader)) {
  //     history.push('/home');
  //   }
  // }, [user]);

  useEffect(() => {
    // console.log("in use call back out");

      if(isEmptyNullUndefined(userToken)){
        // console.log("in use call back if");
        setIsToken("no");
      }
  }, [submit]);
  
  const checkValidation = () => {
    setUsernameText(false);
    if (username && password) {
      dispatch(logInActions.LogInRequest({
        username: username,
        password: password
      }));
    } 
      setSubmit(true);
    
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      checkValidation();
    }
  }

 window.addEventListener('message', function(e){
    // console.log("addEventListener", e.data);
    if(e?.data?.id_token){
      dispatch(logInActions.LogInRequest({
        username: "logInThroughPopupWindow",
        password: e?.data?.id_token
      }))
    }
  })  

  const handleLoginClick = () => {
    const loginWindow = window.open('https://eep.phonepecs.com/saml/login?disco=true&idp=https://accounts.google.com/o/saml2?idpid=C04hjqz9t', 'Login', 'width=500,height=600');
  };

  const SkeletonPage = () => {
    return (
      <div style={{
        gap: '26px',
        padding: '55px',
        display: 'flex',
        flexDirection: 'column',
      }}>
        <Skeleton variant="rounded" width={'80%'} height={60} />

        {/* For other variants, adjust the size with `width` and `height` */}
        <Skeleton variant="circular" width={40} height={40} />
        <Skeleton variant="rounded" width={'80%'} height={60} />
        <Skeleton variant="rounded" width={'80%'} height={60} />
        <Skeleton variant="rounded" width={'80%'} height={60} />
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '80%'
        }}>
        <Skeleton variant="rounded" width={'40%'} height={60} />
        <Skeleton variant="rounded" width={'40%'} height={60} />
        </div>
        <Skeleton variant="rounded" width={'80%'} height={60} />
        <Skeleton variant="circular" width={40} height={40} />
      </div>
    )
  }

  return (
    <div className="login-main">
      {isLoading ? (<div>{SkeletonPage()}</div>) : (
        <div className="row">
          {/* <div className="col-7-left">
            <img src={LoginBg} style={{width: '100%'}} className="loginBg" ></img>
          </div> */}
          <div className="col-5-right">
            <div className="loginBlock">
              <div className="loginBlockInner">
                {/* <img className="logo-img" src={mediLogo} alt="logo" /> */}
                <div className="loginHeadingOuter">
                  <p className="loginHeading">Login</p>
                </div>
                <div className="input-wrapper">
                  <div className="name-ip">
                    <input
                      name="email"
                      onChange={(event) => {setUsername(event.target.value); setIsToken(""); setSubmit(false);}}
                      className={(submit && (username.trim() === "" || (!loading && isToken == "no"))) ? "input border border-red-500 focus:outline-none" : "input focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500"}
                      type="text"
                      placeholder="Username"                
                    />
                    {
                      usernameText &&
                      <p className="helping-text" style={{ lineHeight: '0px'}}>
                        Your username is your company e-mail id.
                      </p>
                    }
                    {submit && (username.trim() === "" || (!loading && isToken == "no")) && (
                      <p className="error-text">
                        Your username is your company e-mail id.<br/>
                        *Please provide a valid Username.
                      </p>
                    )}
                  </div>
                </div>
                <div className="password-wrap">
                  <div className="password-ip">
                    <div className="password-outer">
                    <input
                      name="password"
                      onChange={(event) => {setPassword(event.target.value); setIsToken(""); setSubmit(false);}}
                      className={(submit && (password.trim() === "" || (!loading && isToken == "no"))) ? "input border border-red-500 focus:outline-none" : "input focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500"}
                      type = {showPassword ? "text" : "password"}
                      placeholder="Password"
                      onKeyDown={(e) => handleKeyDown(e)}
                    />
                    <span class="eyeIcon" onClick={() => setShowPassword(!showPassword)}>
                      {
                        showPassword ?
                          <FaEye />
                          :
                          <FaEyeSlash />
                      }               
                    </span>
                    </div>
                    {submit && (password.trim() === "" || (!loading && isToken == "no")) && (
                      <p className="error-text">
                        *Please provide a valid Password.
                      </p>
                    )}
                  </div>
                </div>
                <div className="d-flex justify-content-between pt-4 pb-2">
                  {/* <div className="w-full"> */}
                    {/* <Link className="link">Remember Me</Link> */}
                    <div class="form-check checkbox">
                      <input class="form-check-input" type="checkbox" id="check1" name="option1" value="something" />
                      <label class="form-check-label">Remember Me</label>
                    </div>
                  {/* </div> */}
                  <div className='forgot-pass w-full text-right'>
                    <Link to='/forgot-password' className='link'>
                    Forgot Password ?
                    </Link>
                  </div>
                </div>
                <div className="flex flex-wrap pb-2">
                  <div className="w-full loginBtnOuter" id="login_btn_for_employee">
                    <ButtonM text='Login' onpress={() => checkValidation()} loading={loading} disabled={loading} bgcolor="#00425A" color="#ffffff" bghover="#2e8004" />       
                  </div>
                </div>
                <div className="or-text">-------  OR  -------</div>
                <div className="flex flex-wrap pb-2">
                  <div className="w-full loginBtnOuter" id="login_btn_for_employee">
                    <ButtonM text='Login Via Company' onpress={() => setLoginPopup(true)} loading={loading} disabled={loading} bgcolor="#00425A" color="#ffffff" bghover="#2e8004" />       
                  </div>
                </div>
              </div>
            </div>
          </div>
  
        </div>

      )}
      
      {
        loginPopup &&
        <Popout url='https://eep.phonepecs.com/sso/idpselection' title='SSO Login'  reactDom={ReactDOM}
        // onClosing={() => setLoginPopup(false)}
        onClose={() => setLoginPopup(false)
        }>
        </Popout>
      }
      
    </div>
  );
};

export default LogIn;
