// import Dashboard from "../../../../assets/images/dynamicSidebar/home";
// import Profile from "../../../../assets/images/dynamicSidebar/profile";
// import SelfAssessment from "../../../../assets/images/dynamicSidebar/performance";
// import Givefeedback from "../../../../assets/images/dynamicSidebar/giveFeedback";
// import RatingCollab from "../../../../assets/images/dynamicSidebar/msbr";
// import MyTeam from "../../../../assets/images/dynamicSidebar/myteam";
import Myteam from "../../../../assets/images/dynamicSidebar/myteam";
import EmpProfile from "../../../../assets/images/dynamicSidebar/empProfile";
import EmpPlan from "../../../../assets/images/dynamicSidebar/empPlan";
import TalentNotes from "../../../../assets/images/dynamicSidebar/talentNotes";
import WorkFlows from "../../../../assets/images/dynamicSidebar/workFlows";
import { PiDotDuotone } from "react-icons/pi";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
// import FunctionalLeader from "../../../../assets/images/dynamicSidebar/functionalLeader";
// import ManagerCalibration from "../../../../assets/images/dynamicSidebar/functionalLeader"


export const allRoutes = [
    {
        path: "/team-view",
        name: "Team View",
        icon: <Myteam />,
      },
      {
        // path: "employee-profile",
        path: "/employee-profile/profile",
        name: "Employee Profile",
        icon: <EmpProfile />,
        // subcategory: [          
          // {
          //   path: "/employee-profile/experience",
          //   name: "Experience",
          //   icon: <PiDotDuotone />,
          // },
          // {
          //   path: "/employee-profile/Education",
          //   name: "Education & Certification",
          //   // icon: <SelfAssessment />,
          // },
          // {
          //   path: "/employee-profile/Performance-History",
          //   name: "Performance History",
          //   // icon: <Givefeedback />,
          // } ,
          // {
          //   path: "/employee-profile/Interest-Aspiration",
          //   name: "Interest & Aspiration",
          //   // icon: <Givefeedback />,
          // } ,
          // {
          //   path: "/employee-profile/Learning-Development",
          //   name: "Learning & Development",
          //   // icon: <Givefeedback />,
          // } ,
        // ]
      } , 
      /////// hide 1 - july //start///    
      // {
      //   path: "Employee-Plan",
      //   name: "Employee Plan",
      //   icon: <EmpPlan />,
      //   subcategory: [          
      //     {
      //       path: "/Employee-Plan/Summary",
      //       name: "Summary",
      //       // icon: <SelfAssessment />,
      //     },
      //     {
      //       path: "/Employee-Plan/My-Plan",
      //       name: "My Plan",
      //       // icon: <SelfAssessment />,
      //     },
      //     {
      //       path: "/Employee-Plan/Check-Ins",
      //       name: "Check-Ins",
      //       // icon: <SelfAssessment />,
      //     },
      //   ]
      // } ,    
      // {
      //   path: "/Talent-Notes",
      //   name: "Talent Notes",
      //   icon: <TalentNotes />,
      // },
      
      // {
      //   path: "/Workflows",
      //   name: "Workflows",
      //   icon: <WorkFlows />,
      // },  
      /////// hide 1 - july //start///    
];

export const prodRoutes = [   
     {
        path: "/profile",
        name: "My Profile",
        // icon: <Profile />,
      },  
      {
        path: "/feedback",
        name: "My Team",
        // icon: <MyTeam />,
      },
     
]