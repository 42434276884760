const { createAction } = require('redux-actions');
const EMPLOYEE_DATA_REQUEST = 'MEDIBUDDY/EMPLOYEE_DATA_REQUEST';
const EMPLOYEE_DATA_REQUEST_SUCCESS = 'MEDIBUDDY/EMPLOYEE_DATA_REQUEST_SUCCESS';
const EMPLOYEE_DATA_REQUEST_FAILED = 'MEDIBUDDY/EMPLOYEE_DATA_REQUEST_FAILED';

const PERFORMANCE_HISTORY = 'MEDIBUDDY/PERFORMANCE_HISTORY';
const PERFORMANCE_HISTORY_SUCCESS = 'MEDIBUDDY/PERFORMANCE_HISTORY_SUCCESS';
const PERFORMANCE_HISTORY_FAILED = 'MEDIBUDDY/PERFORMANCE_HISTORY_FAILED';



const EmployeeDataRequest = createAction(EMPLOYEE_DATA_REQUEST);
const EmployeeDataRequestSuccess = createAction(EMPLOYEE_DATA_REQUEST_SUCCESS);
const EmployeeDataRequestFailed = createAction(EMPLOYEE_DATA_REQUEST_FAILED);

const PerformanceHistoryRequest = createAction(PERFORMANCE_HISTORY);
const PerformanceHistoryRequestSuccess = createAction(PERFORMANCE_HISTORY_SUCCESS);
const PerformanceHistoryRequestFailed = createAction(PERFORMANCE_HISTORY_FAILED);

export const actions = {
    EmployeeDataRequest,
    EmployeeDataRequestSuccess,
    EmployeeDataRequestFailed,
    PerformanceHistoryRequest,
    PerformanceHistoryRequestSuccess,
    PerformanceHistoryRequestFailed
};

export const types = {
    EMPLOYEE_DATA_REQUEST,
    EMPLOYEE_DATA_REQUEST_SUCCESS,
    EMPLOYEE_DATA_REQUEST_FAILED,
    PERFORMANCE_HISTORY,
    PERFORMANCE_HISTORY_SUCCESS,
    PERFORMANCE_HISTORY_FAILED
};
