import { Button, CircularProgress, createTheme, ThemeProvider } from "@mui/material";



const ButtonM = ({text, type, disabled, loading, onpress, color, bgcolor, bghover, variant, width, textsize}) => {

  const btnTheme = createTheme({
    palette: {
      action: {
        disabledBackground:(bgcolor ||'var(--bredcumb-title-color)'),
      },
    },
  });


  const textStyle = {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: textsize || '1rem',
    lineHeight: '1.5rem',
    color: color || '#e7f6ff',
    padding: '0.25rem 0',
  }

    return(
        <ThemeProvider theme={btnTheme}>

              <Button
                variant= {variant || "contained"}
                size="large"
                type={type || 'submit'}
                onClick={() => onpress()}
                sx={{
                  mt: 3,
                  backgroundColor: variant ==='outlined' ? '#ffffff' : (bgcolor || "#3E4359"),
                  width: width || "100%",
                  boxShadow: "none",
                  borderColor: variant ==='outlined' ? '#3E4359' : '',
                  "&:hover": {
                    backgroundColor: bghover || "#3E4359",
                    boxShadow: "none",
                    opacity:"0.9"
                  },
                  "&:active": {
                    boxShadow: "none",
                    backgroundColor: "#f6de97",
                    borderColor: "#005cbf",
                  },
                }}
                disabled={disabled || false}
              >
                {
                    loading ?
                <CircularProgress color="success" size={29} />
                :
                <p className="btnText" style={textStyle}>                
                {text}               
                </p>
                }
              </Button>
              </ThemeProvider>
    )
}

export default ButtonM;