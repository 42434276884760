const { createAction } = require('redux-actions');
const LOGIN_REQUEST = 'BENEVOLVE/LOGIN_REQUEST';
const LOGIN_REQUEST_SUCCESS = 'BENEVOLVE/LOGIN_REQUEST_SUCCESS';
const LOGIN_REQUEST_FAILED = 'BENEVOLVE/LOGIN_REQUEST_FAILED';
const CLEAR_LOGIN_TOKEN = 'BENEVOLVE/CLEAR_LOGIN_TOKEN';

const USER_REQUEST = 'BENEVOLVE/USER_REQUEST';
const USER_REQUEST_SUCCESS = 'BENEVOLVE/USER_REQUEST_SUCCESS';
const USER_REQUEST_FAILED = 'BENEVOLVE/USER_REQUEST_FAILED';

const LogInRequest = createAction(LOGIN_REQUEST);
const LogInRequestSuccess = createAction(LOGIN_REQUEST_SUCCESS);
const LogInRequestFailed = createAction(LOGIN_REQUEST_FAILED);
const clearLogInToken = createAction(CLEAR_LOGIN_TOKEN);


const UserRequest = createAction(USER_REQUEST);
const UserRequestSuccess = createAction(USER_REQUEST_SUCCESS);
const UserRequestFailed = createAction(USER_REQUEST_FAILED);

export const actions = {
    LogInRequest,
    LogInRequestSuccess,
    LogInRequestFailed,
    clearLogInToken,

    UserRequest,
    UserRequestSuccess,
    UserRequestFailed,
};

export const types = {
    LOGIN_REQUEST,
    LOGIN_REQUEST_SUCCESS,
    LOGIN_REQUEST_FAILED,
    CLEAR_LOGIN_TOKEN,
    USER_REQUEST,
    USER_REQUEST_SUCCESS,
    USER_REQUEST_FAILED,
};
