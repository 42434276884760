import React, { useEffect, useState } from 'react';
import { IoClose } from "react-icons/io5";
import APIList from '../../../api';
import { RiErrorWarningFill } from 'react-icons/ri';
import { getListOfArrayFromObjKey, isEmptyNullUndefined, isEmptyObj, returnNameByKey, sortStringAscending, sortStringAscendingByType } from '../../utils/utils';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Accordion, AccordionDetails, AccordionSummary, CircularProgress, Skeleton, Stack } from "@mui/material";
// import './Filters.css';

// const FilterGroup = ({ options, selectedOptions, handleOptionChange }) => {
//   return (
//     <div className="filter-group">
//       {options.map((option, index) => (
//         <label key={index} className="filter-option">
//           <input
//             type="checkbox"
//             checked={selectedOptions.includes(option)}
//             onChange={() => handleOptionChange(option)}
//           />
//           {option}
//         </label>
//       ))}
//     </div>
//   );
// };

const sortByChangedName = (list) => {
  // we are not showing actual key in jsx so we sort by which we are viewing
  return list.sort((a,b) => returnNameByKey(a).localeCompare(returnNameByKey(b)))
}

const Filters = ({ handleCloseFilter, handleFilteredData, parentSelectedOptions, handleUpdateParentfilterDataList, handleSetInitialSelectedOptions }) => {
  const employeeDetails = useSelector((state) => state?.empData?.empData);
  const filterGroups = {
    Gender: ['Male', 'Female', 'Other'],
    Grade: ['A', 'B', 'C'],
    'Role Name': ['Manager', 'Developer', 'Designer'],
    'Sub-Department': ['HR', 'Engineering', 'Design'],
    Pod: ['Pod 1', 'Pod 2', 'Pod 3'],
    'Time in Role': ['<1 Year', '1-2 Years', '3+ Years'],
    'Time since Last Promotion': ['<1 Year', '1-2 Years', '3+ Years'],
    'PhonePe Tenure': ['<1 Year', '1-2 Years', '3+ Years'],
    'Performance Cycle Event': ['Event 1', 'Event 2', 'Event 3'],
    'Education: Institute': ['Institute 1', 'Institute 2', 'Institute 3'],
    'Previous Company': ['Company 1', 'Company 2', 'Company 3'],
  };

  const initialFilters = {
    // employeeGrade: [],
    // employeeLevel: [],
    // employeeSubfunction: [],
    // gender: [],
    // pod: [],
    // // tenure: [],

    // phonepeTenure: [],
    // timeSinceLastPromotion: [],
  }

  const [filterData, setFilterData] = useState(JSON.parse(JSON.stringify(initialFilters)))
  // const [activeFilter, setActiveFilter] = useState(Object.keys(filterGroups)[0]);
  const [activeFilter, setActiveFilter] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState(JSON.parse(JSON.stringify(initialFilters)));

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if(!isEmptyNullUndefined(employeeDetails?.company?.id)) {
      getFilters()
    }
  }, [employeeDetails])

  useEffect(() => {
    setActiveFilter(() => 'employeeLevel')
    if(!isEmptyNullUndefined(parentSelectedOptions)) {
      setSelectedOptions(() => parentSelectedOptions)
      console.log('parentSelectedOptions', parentSelectedOptions)
    }
  }, [])

  const getFilters = async() => {
    setLoading(() => true)
    // GET : {{psm_url}}/api/new-employees/filters/ba02f418-e44d-467c-9d5d-421a2d966460  {company_id}

        APIList.get_new_employees_filters(employeeDetails.company.id)
        .then((res) => {
          let tempResData = JSON.parse(JSON.stringify(res.data))

          // tempResData.employeeGrade.forEach((e) => e.key = e.id)
          // tempResData.employeeLevel.forEach((e) => e.key = e.id)
          // tempResData.employeeSubfunction.forEach((e) => e.key = e.id)

          

          // tempResData.gender = tempResData.gender.map((gender) => ({ name: gender, id: gender, key: gender }));

          // if(!isEmptyNullUndefined(tempResData?.employeeGender)) {
          //   tempResData.employeeGender = tempResData.employeeGender.map(employeeGender => ({ name: employeeGender, id: employeeGender, key: employeeGender }));
          // }

          let initialSelectedOption = {}
          for(let key in tempResData) {
            console.log('key', key)
            if(tempResData[key].length > 0) {
              console.log('tempResData?.key?.length', tempResData?.key?.length)
              if(isEmptyNullUndefined(tempResData[key][0].id)) {
                tempResData[key] = tempResData[key].map(key => ({ name: key, id: key, key: key }));
              }
            }

            initialSelectedOption[key] = []
          }


          // tempResData.phonepeTenure = tempResData.phonepeTenure.map(phonepeTenure => ({ name: phonepeTenure, id: phonepeTenure, key: phonepeTenure }));
          // tempResData.timeSinceLastPromotion = tempResData.timeSinceLastPromotion.map(timeSinceLastPromotion => ({ name: timeSinceLastPromotion, id: timeSinceLastPromotion, key: timeSinceLastPromotion }));
          
          // tempResData.pod = tempResData.pod.map(pod => ({ name: pod, id: pod, key: pod }));
          console.log('tempResData', tempResData)
          // console.log('genderObj', genderObjects)
            // setMyTeamData(res?.data);
            // setMyTeamLoader(false);

            setFilterData(() => tempResData)

            if(isEmptyObj(parentSelectedOptions)) {
              console.log('yes parent is empty')
              setSelectedOptions(() => initialSelectedOption)
            } else {
              console.log('no parent is no')
            }
            // handleSetInitialSelectedOptions(initialSelectedOption)
            setLoading(() => false)
        }).catch((err) => {
            toast.error(
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                    &nbsp;&nbsp;{err?.message}
                </div>
            );
            // setMyTeamLoader(false);
            setLoading(() => false)
        })
  }

  const handleOptionChange = (filterE, filterI, tempfilterData, activeFilter, tempselectedOptions) => {

    if(filterE == 'selectAll') {
      if(selectedOptions[activeFilter].length == filterData[activeFilter].length) {
        tempselectedOptions[activeFilter].length = 0
      } else {
        let tempSelectAllData = []
        tempfilterData[activeFilter].forEach((e, i) => {
          tempSelectAllData.push(e.id)
        })
        tempselectedOptions[activeFilter] = tempSelectAllData
      }
      
    } else {

      if(tempselectedOptions[activeFilter].includes(filterE.id)) {
        let selectedIndex = tempselectedOptions[activeFilter].findIndex((e) => e == filterE.id)
        tempselectedOptions[activeFilter].splice(selectedIndex, 1)
      } else {
        tempselectedOptions[activeFilter].push(filterE.id)
      }
    }


    setSelectedOptions(() => tempselectedOptions)
    
    console.log('filterE', filterE)
    console.log('filterI', filterI)
    // setSelectedOptions((prev) => {
    //   const options = prev[filter] || [];
    //   return {
    //     ...prev,
    //     [filter]: options.includes(option)
    //       ? options.filter((o) => o !== option)
    //       : [...options, option],
    //   };
    // });
  };

  const handleResetFilter = () => {
    let tempselectedOptions = JSON.parse(JSON.stringify(selectedOptions)) 

    let initialFilters = {}

    for(let key in tempselectedOptions) {
      initialFilters[key] = []
    }



    setSelectedOptions(() => initialFilters)
  }

  return (
    <div className="setting-dropdown-main-container">
        <div className='d-flex justify-content-between top-header p-3'>
            <div className='filter-header'>Filters</div>
            <div 
              style={{cursor: 'pointer'}} 
              className='align-items-center d-flex close-dilog'
              onClick={() => handleCloseFilter()}
            ><IoClose /></div>
        </div>
        
        <div>
            <div className="filters">
                <div className="filter-nav">
                    {/* {Object.keys(filterGroups).map((filter, index) => (
                      <div
                          key={index}
                          className={`filter-nav-item ${activeFilter === filter ? 'active' : ''}`}
                          onClick={() => setActiveFilter(filter)}
                      >
                          {filter}
                      </div>
                    ))} */}
                    <div>
                    
                    
                      {filterData && (sortByChangedName(getListOfArrayFromObjKey(filterData)))?.map((filterKeyE, index) => (
                              <div
                                key={filterKeyE + "-" + index}
                                className={`filter-nav-item ${activeFilter === filterKeyE ? 'active' : ''}`}
                                onClick={() => setActiveFilter(filterKeyE)} //filterData?.filterKeyE
                              >
                                  {returnNameByKey(filterKeyE)}
                              </div>
                            
                          ))}

                    
                    </div>
                      {/* <div
                          key={'employeeLevel'}
                          className={`filter-nav-item ${activeFilter === 'employeeLevel' ? 'active' : ''}`}
                          onClick={() => setActiveFilter('employeeLevel')} //filterData?.employeeLevel
                      >
                          Role Name
                      </div>
                      <div
                          key={'employeeGrade'}
                          className={`filter-nav-item ${activeFilter === 'employeeGrade' ? 'active' : ''}`}
                          onClick={() => setActiveFilter('employeeGrade')} //filterData?.employeeGrade
                      >
                          Grade
                      </div>
                      <div
                          key={'employeeSubfunction'}
                          className={`filter-nav-item ${activeFilter === 'employeeSubfunction' ? 'active' : ''}`}
                          onClick={() => setActiveFilter('employeeSubfunction')} //filterData?.employeeSubfunction
                      >
                          Sub Department
                      </div>
                      <div
                          key={'jobCode'}
                          className={`filter-nav-item ${activeFilter === 'jobCode' ? 'active' : ''}`}
                          onClick={() => setActiveFilter('jobCode')} //filterData?.jobCode
                      >
                          Job Code
                      </div>
                      <div
                          key={'pod'}
                          className={`filter-nav-item ${activeFilter === 'pod' ? 'active' : ''}`}
                          onClick={() => setActiveFilter('pod')} //filterData?.pod
                      >
                          Pod
                      </div>
                      <div
                          className={`filter-nav-item ${activeFilter === 'tenure' ? 'active' : ''}`}
                          onClick={() => setActiveFilter('tenure')} //filterData?.tenure
                      >
                          PhonePe Tenure
                      </div>
                      <div
                          style={{pointerEvents: 'none', opacity: '40%'}}
                          key={'employeeType'}
                          className={`filter-nav-item ${activeFilter === 'employeeType' ? 'active' : ''}`}
                          onClick={() => setActiveFilter('employeeType')} //filterData?.employeeType
                      >
                          Employee Type
                      </div>
                      <div
                          style={{pointerEvents: 'none', opacity: '40%'}}
                          key={'employementStatus'}
                          className={`filter-nav-item ${activeFilter === 'employementStatus' ? 'active' : ''}`}
                          onClick={() => setActiveFilter('employementStatus')} //filterData?.employementStatus
                      >
                          Payroll Entity
                      </div>
                      <div
                          style={{pointerEvents: 'none', opacity: '40%'}}
                          key={'employeeFunction'}
                          className={`filter-nav-item ${activeFilter === 'employeeFunction' ? 'active' : ''}`}
                          onClick={() => setActiveFilter('employeeFunction')} //filterData?.employeeFunction
                      >
                          Employee Function
                      </div>
                      <div
                          style={{pointerEvents: 'none', opacity: '40%'}}
                          key={'employeeStatus'}
                          className={`filter-nav-item ${activeFilter === 'employeeStatus' ? 'active' : ''}`}
                          onClick={() => setActiveFilter('employeeStatus')} //filterData?.employeeStatus
                      >
                          Employee Status
                      </div> */}
                      
                      
            
                </div>
                <div className="filter-options">
                  {loading ? (
                    <div className='d-flex w-full h-100 justify-content-center align-items-center'>
                      <CircularProgress />

                    </div>

                  ) : (
                    <>
                      <label key={'selectAll'} className="filter-option">
                      <input
                        key={'selectAll'}
                        id={'selectAll'}
                        type="checkbox"
                        checked={selectedOptions?.[activeFilter]?.length == filterData?.[activeFilter]?.length}
                        onChange={() => handleOptionChange('selectAll', null, JSON.parse(JSON.stringify(filterData)), activeFilter, JSON.parse(JSON.stringify(selectedOptions)))}
                      />
                      Select All
                      </label>
                      <div className={`checkbox-item-container-${activeFilter == 'phonepeTenure' ? 'one' : 'two'}-card`}>

                        {/* {(!isEmptyNullUndefined(filterData[activeFilter])) && (filterData[activeFilter]?.length > 0) && (sortStringAscendingByType(filterData[activeFilter], 'name')).map((filterE, filterI) => { */}
                        {(!isEmptyNullUndefined(filterData[activeFilter])) && (filterData[activeFilter]?.length > 0) && (filterData[activeFilter]).map((filterE, filterI) => {
                          return (
                            <label key={filterI} className="filter-option">
                              <input
                                key={filterE.key}
                                id={filterE.id}
                                type="checkbox"
                                // checked={selectedOptions.includes(option)}
                                checked={selectedOptions[activeFilter]?.includes(filterE.id)}
                                onChange={() => handleOptionChange(filterE, filterI, JSON.parse(JSON.stringify(filterData)), activeFilter, JSON.parse(JSON.stringify(selectedOptions)))}
                              />
                              <div className='filter-item-title'>
                                {filterE.name}
                              </div>
                            </label>
                          )
                        })}
                      </div>
                    </>
                  )}


                  

                  
                    
                </div>
            </div>

        </div>

        <div>

        <div className="filter-actions p-3">
                    <button onClick={() => handleResetFilter()}>Clear all filters</button>
                    <button onClick={() =>  {handleFilteredData(selectedOptions); handleUpdateParentfilterDataList(filterData)}}>Apply</button>
                    </div>

        </div>

    </div>
  );
};

export default Filters;
