import React from 'react';
import { Radar } from 'react-chartjs-2';
import { Chart as ChartJS, RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend } from 'chart.js';

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend);

const Movement = ({data}) => {
    // Prepare data for Radar Chart
    const labels = Object.keys(data);
    const expectedRatings = labels.map(label => data[label].expectedRating);
    const actualRatings = labels.map(label => data[label].actualRating);
    const actualRatingCurrentYear = labels.map(label => data[label].actualRatingCurrentYear);
    const actualRatingLastYear = labels.map(label => data[label].actualRatingLastYear);

    const chartData = {
        labels: labels,
        datasets: [
            // hide for sprint one
            // {
            //     label: 'Expected Rating',
            //     data: expectedRatings,
            //     backgroundColor: 'rgba(255, 99, 132, 0.2)',
            //     borderColor: 'rgba(255, 99, 132, 1)',
            //     borderWidth: 1,
            // },
            // {
            //     label: 'Actual Rating',
            //     data: actualRatings,
            //     backgroundColor: 'rgba(54, 162, 235, 0.2)',
            //     borderColor: 'rgba(54, 162, 235, 1)',
            //     borderWidth: 1,
            // },
            
            {
                label: 'Actual: Last Year',
                data: actualRatingLastYear,
                backgroundColor: 'rgba(23, 78, 235, 0.2)',
                borderColor: 'rgba(23, 78, 235, 1)',
                borderWidth: 1,
            },
            {
                label: 'Actual: Current Year',
                data: actualRatingCurrentYear,
                backgroundColor: 'rgba(54, 162, 235, 0.2)',
                borderColor: 'rgba(54, 162, 235, 1)',
                borderWidth: 1,
            },
        ],
    };

    const chartOptions = {
        scales: {
            r: {
                min: 0,
                max: 5,
                ticks: {
                    stepSize: 1
                }
            },
        },
        plugins: {
            legend: {
                position: 'bottom',
            },
            tooltip: {
                callbacks: {
                    label: function(context) {
                        return ''; // Return an empty string to avoid showing numeric values
                    }
                }
            }
        }
    };

    return (
        <div>
            <Radar data={chartData} options={chartOptions} />
        </div>
    );
};

export default Movement;
