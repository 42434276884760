import React, { Suspense, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Route, useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Home from "../components/pages/home";
import EmpProfile from "../components/pages/employeeProfile";
import EmpPlan from "../components/pages/employeePlan";
import TalentNotes from "../components/pages/talentNotes";
import LogIn from "../components/common/Login";
import teamview from "../components/pages/teamview";

const AppRouters = () => {

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
 
  return (
    <Suspense fallback={<div></div>}>
       
      <>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          closeOnClick
        />
        <div>
        <Route exact path="/" component={LogIn} />
        <Route exact path="/login" component={LogIn} /> 

        <Route exact path="/home" component={Home} />
        <Route exact path="/employee-profile/:id" component={EmpProfile} />
        <Route exact path="/employee-plan" component={EmpPlan} />
        <Route exact path="/talent-notes" component={TalentNotes} />
        <Route exact path="/team-view" component={teamview} />
        
        </div>
      </>
     
    </Suspense>
  );
};

export default AppRouters;
