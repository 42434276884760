import rightArrow from "../../../assets/images/rightArrow.svg"
import searchIcon from "../../../assets/images/searchIcon.svg"
import userImage from "../../../assets/images/user.png"
import hand from "../../../assets/images/hand.svg"
import mail from "../../../assets/images/mail.svg"
import phone from "../../../assets/images/phone.svg"
import plus from "../../../assets/images/plus.svg"
import filter from "../../../assets/images/filter.svg"
import minusClose from "../../../assets/images/minusClose.svg"
import chartImg from "../../../assets/images/canvas_image.png"
import plusOpen from "../../../assets/images/plusOpen.svg"
import pdfDownload from "../../../assets/images/pdfDownload.svg"
import selectDropDown from "../../../assets/images/selectDropdown.svg"
import movementJourney from "../../../assets/images/movementJourney.png"
import roundIcon from "../../../assets/images/roundIcon.svg"
import linkIcon from "../../../assets/images/link.svg"
import plusWhite from "../../../assets/images/plusWhite.svg"
import notes from "../../../assets/images/notes.svg"
import circleArrow from "../../../assets/images/circleArrow.svg"
import edit from "../../../assets/images/edit.svg"
import info from "../../../assets/images/info.svg"
import illustration from "../../../assets/images/Illustration.svg"
import send from "../../../assets/images/send.svg"
import deleteImg from "../../../assets/images/delete.svg"
import editDisabled from "../../../assets/images/editDisabled.svg"
import infoCircular from "../../../assets/images/infoCircular.svg"
import crossImg from "../../../assets/images/cross.svg"

import { useEffect, useState } from "react"
import APIList from "../../../api"
import { RiErrorWarningFill } from "react-icons/ri"
import { useSelector,useDispatch} from "react-redux"
import { toast } from "react-toastify"
import { actions as EmployeeDataActions } from "../../../redux/employeeData/actions";

const EmployeePlan = () => {

    const employeeDetails = useSelector((state) => state?.empData?.empData);
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState("MyPlan");
    const [activeTab2, setActiveTab2] = useState("Open");
    const [activeTab3, setActiveTab3] = useState("Task1");

    const [myTeamData, setMyTeamData] = useState([]);
    const [myTeamLoader, setMyTeamLoader] = useState(false);

    useEffect(() => {
        setMyTeamLoader(true)
        APIList.getMyTeam({
            payload: {
                employeeEligibilityDTO: {},
                filterData: false,
                keyword: "",
                // managerId: employeeDetails?.id,
                // companyId: employeeDetails?.company?.id,
                "managerId": "4b6fa605-ba6c-41ed-8d35-6159cf78c6de",
                "companyId": "ba02f418-e44d-467c-9d5d-421a2d966460",
                page: "My Team"
            },
            page: 0,
            size: 10
        }).then((res) => {
            setMyTeamData(res?.data);
            setMyTeamLoader(false);
        }).catch((err) => {
            toast.error(
                <div style={{display:"flex", flexDirection:"row"}}>
                <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                &nbsp;&nbsp;{err?.title}
                </div>
            );
            setMyTeamLoader(false);
        })
    }, [])

    useEffect(() => {
        console.log("is here")
        //if (!isEmptyNullUndefined(userToken)) {
          dispatch(EmployeeDataActions.PerformanceHistoryRequest());
        // } else {
        //   history.push("/login");
        // }
      }, []);

    useEffect(() => {
        fetch('https://jsonplaceholder.typicode.com/posts/1')
            .then(response => response.json())
            .then(data => {
                setData({
                    title: data.title, 
                    subTitle: data.body
                });
            })
            .catch(error => console.error('Error fetching data:', error));
    }, []);

    const [data, setData] = useState({
        title: '',
        subTitle: ''
    });
    
    return(
        <div className="emp-plan-main">
            <p className="header-title">Employee Profile</p>
            <div className="breadcumb">
                <p className="mainPage">Team View</p>
                <img src={rightArrow} />
                <p className="subPage">Member</p>
            </div>
            <div className="profile">
                <div className="searchBar">
                    <div className="search-title-outer">
                        <p className="search-title">Search for Team Members</p>
                        <p className="search-subTitle">In publishing and graphic design, Lorem ipsum is a placeholder text</p>
                    </div>
                    <div className="search">
                        <input placeholder="Search" className="searchInput" />
                        <img src={searchIcon} className="searchIcon" />
                    </div>
                </div>
                <div className="profile-table none">
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    <div className="filter">
                                        <p className="label">Name</p> 
                                        <img src={filter} />
                                    </div>
                                </th>
                                <th>Designation</th>
                                <th>PhonePe Tenure</th>
                                <th>Level</th>
                                <th>Time since last promotion</th>
                                <th>
                                    <div className="filter">
                                        <p className="label">EEP Completion</p> 
                                        <img src={filter} />
                                    </div>    
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                            myTeamData?.data?.map((val,index) =>{
                                return (
                                    <tr key={index}>
                                    <td>
                                        <div className="details">
                                        {val?.isManager ? <img src={plus} alt="plus" /> : null}
                                            <img src={userImage} className="userImage" />
                                            <div className="detailsInner">
                                                <p className="title">{val?.employeeName}</p>
                                                <p className="empId">Emp id: {val?.employeeId}</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td>{val?.employeeDesignation}</td>
                                    <td>{val?.tenure}</td>
                                    <td>{val?.employeeLevel}</td>
                                    <td>{val?.timeSinceLastPromotion}</td>
                                    <td>{val?.eepCompletion}</td>
                                </tr>
                                )
                            })
                            }
                           
                            {/* <tr>
                                <td>
                                    <div className="details">
                                        <img src={plus} />
                                        <img src={userImage} className="userImage" />
                                        <div className="detailsInner">
                                            <p className="title">Manish Jain</p>
                                            <p className="empId">Emp id: 256987452</p>
                                        </div>
                                    </div>
                                </td>
                                <td>Chief Finance Officer - Corporate - Finance</td>
                                <td>2 Years</td>
                                <td>L1</td>
                                <td>7 Months</td>
                                <td>70%</td>
                            </tr> */}
                        </tbody>
                    </table>
                </div>
                <div className="profile-details">
                    <img src={userImage} className="userImage" />
                    <div className="details">
                        <div className="detailsOne">
                            <div className="detailsInner">
                                <p className="userName">Venkateshan V</p>
                                <p className="dept">Head - Business Finance</p>
                                <p className="empId">Employee ID: 410905</p>
                                <div className="cardOuter">
                                    <p className="card">BU: Corporate</p>
                                    <p className="card">Dept: HR Ops & Technology</p>
                                </div>
                            </div>
                            <div className="contact">
                                <div className="mailOuter">
                                    <img src={mail} />
                                    <p className="mail">Venkateshan.v@phonepe.com</p>
                                </div>
                                <div className="mobNoOuter">
                                    <img src={phone} />
                                    <p className="mobNo">(+91) 1234567890</p>
                                </div>
                            </div>
                        </div>
                        <div className="detailsTwo">
                            <div className="detailsTwoInner">
                                <p className="title">Grade</p>
                                <p className="subTitle">A</p>
                            </div>
                            <div className="detailsTwoInner">
                                <p className="title">PhonePe Tenure</p>
                                <p className="subTitle">2 Years</p>
                            </div>
                            <div className="detailsTwoInner">
                                <p className="title">Time Since Last Promotion</p>
                                <p className="subTitle">7 Months</p>
                            </div>
                            <div className="detailsTwoInner">
                                <p className="title">EEP Completion %</p>
                                <p className="subTitle">70%</p>
                            </div>
                        </div>
                        <div className="about">
                            <img src={hand} />
                            <div className="aboutInner">
                                <p className="title">About me</p>
                                <p className="desc">My name is [Your name], and I'm passionate about [Interest], [Interest] and [Interest]. I'm currently working as a [Your current job title] where I practise [Relevant skill] and [Relevant skill] every day.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="tabs3" >
                    <div className="tabs-block">
                        <div className={`tab ${activeTab === "Summary" ? "active" : ""}`} onClick={() => setActiveTab("Summary")}>Summary</div>
                        <div className={`tab ${activeTab === "MyPlan" ? "active" : ""}`} onClick={() => setActiveTab("MyPlan")}>My Plan</div>
                        <div className={`tab ${activeTab === "CheckIns" ? "active" : ""}`} onClick={() => setActiveTab("CheckIns")}>Check-Ins</div>
                    </div>
                    {
                        activeTab === "Summary" && 
                        <div className="summary">
                           <div className="block">
                                <div className="titleOuter">
                                    <p className="title">Goal Plan</p>
                                    <div className="selectOuter">
                                    <select>
                                        <option>July 2022-June 2023</option>
                                        <option>Work In Progress</option>
                                        <option>Work In Progress</option>
                                        <option>Work In Progress</option>
                                        <option>Work In Progress</option>
                                    </select>
                                    </div>
                                </div>
                            </div>
                            <div className="blockOuterContent">
                                <div className="firstBlock">
                                    <p className="title">1. Output / Development Goal</p>
                                    <div className="blockOuter">                            
                                        <div className="block">
                                        <p className="title">Courses</p>
                                            <div className="titleOuter">
                                            <p className="ingoing">On going</p>
                                            <p className="countBox">4</p>
                                            </div>
                                            <div className="titleOuter">
                                            <p className="complited">Completed</p>
                                            <p className="countBox">2</p>
                                            </div>
                                        </div>
                                        <div className="block">
                                            <p className="title">Mentorship</p>
                                            <div className="titleOuter">
                                            <p className="ingoing">On going</p>
                                            <p className="countBox">0</p>
                                            </div>
                                            <div className="titleOuter">
                                            <p className="complited">Completed</p>
                                            <p className="countBox">1</p>
                                            </div>
                                        </div>
                                        <div className="block">
                                            <p className="title">Projects/Assignment</p>
                                            <div className="titleOuter">
                                            <p className="ingoing">On going</p>
                                            <p className="countBox">3</p>
                                            </div>
                                            <div className="titleOuter">
                                            <p className="complited">Completed</p>
                                            <p className="countBox">2</p>
                                            </div>
                                        </div>        
                                    </div> 
                                </div>
                                <div className="secondBlock">
                                <p className="title">2. Competencies </p>
                                    <div className="BadgeOuter">                                   
                                        <div className="chartImg">
                                        <img src={chartImg} className="openCloseImg" />
                                        </div>
                                        <div className="badgeList">
                                            <div className="beadgeOne">
                                                <p className="BadgeMaintitle">In focus <span>(8)</span></p>
                                                <div class="demo-preview">
                                                    <div class="badge badge-default">Coding</div>
                                                    <div class="badge badge-default">Understanding of Systems</div>
                                                    <div class="badge badge-default">Design</div>
                                                    <div class="badge badge-default">NFRs</div>
                                                    <div class="badge badge-default">Engineering Domain Depth (Tech)</div>
                                                    <div class="badge badge-default">Delivery, Planning and Prioritization</div>
                                                    <div class="badge badge-default">Engineering Domain Depth (Business)</div>
                                                    <div class="badge badge-default">Dealing with change</div>
                                                </div>
                                            </div> 
                                            <div className="beadgeOne">
                                                <p className="BadgeWprkedOn">Worked on <span>(5)</span></p>
                                                <div class="demo-preview">
                                                    <div class="badge badge-default">Communication (Stakeholder Management)</div>
                                                    <div class="badge badge-default">Operational Excellence</div>
                                                    <div class="badge badge-default">Influence/Building Consensus</div>
                                                    <div class="badge badge-default">Hiring and Building Capability in other</div>
                                                    <div class="badge badge-default">Problem solving</div>
                                                    <div class="badge badge-default">Strategy and Direction</div>
                                                </div>
                                            </div> 
                                        </div>                                                
                                    </div>
                                </div>
                            </div> 
                        </div>
                    }

                    {
                        activeTab === "MyPlan" && 
                        <div className="myPlan">
                            <div className="blockOuter">
                                <div className="block">
                                    <div className="titleOuter">
                                        <p className="title">1. Output / Development Goal</p>
                                        <button className="addGoal">
                                            <img src={plusWhite} />
                                            <p className="btnText">Add Goal</p>
                                        </button>
                                    </div>
                                    <div className="tabs2">
                                        <div className="tabs-block tabs2Block">
                                            <div className={`tab ${activeTab2 === "Open" ? "active" : ""}`} onClick={() => setActiveTab2("Open")}>Open</div>
                                            <div className={`tab ${activeTab2 === "Completed" ? "active" : ""}`} onClick={() => setActiveTab2("Completed")}>Completed</div>
                                            <div className={`tab ${activeTab2 === "Archived" ? "active" : ""}`} onClick={() => setActiveTab2("Archived")}>Archived</div>
                                        </div>
                                        {
                                            activeTab2 === "Open" && 
                                            <div className="open">
                                                <div className="boxOuter">
                                                    <img src={plusOpen} className="openCloseImg" />
                                                    <div className="dataOuter">
                                                        <div className="data">
                                                            <div className="box">
                                                                <p className="count">1.</p>
                                                                <div className="details">
                                                                    <p className="detailsName">Drive closure of IDP Project</p>
                                                                    <div className="detailsInner">
                                                                        <p className="title">Tasks : </p>
                                                                        <p className="subTitle">0 Live</p>
                                                                        <p className="dot">&nbsp;</p>
                                                                        <p className="title">Due Date:</p>
                                                                        <p className="subTitle">December 15, 2022</p>
                                                                        <p className="dot">&nbsp;</p>
                                                                        <p className="title">Status:</p>
                                                                        {/* <p className="status">Work In Progress</p> */}
                                                                        <div className="selectOuter">
                                                                        <select>
                                                                            <option>Work In Progress</option>
                                                                            <option>Work In Progress</option>
                                                                            <option>Work In Progress</option>
                                                                            <option>Work In Progress</option>
                                                                            <option>Work In Progress</option>
                                                                        </select>
                                                                        </div>
                                                                        <p className="menu"></p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="action">
                                                                <div className="showNotes">
                                                                    <img src={notes} className="showNotesImg" />
                                                                    <p className="text">Show Notes</p>
                                                                </div>
                                                                <div className="edit">
                                                                    <img src={edit} className="editImg" />
                                                                    <p className="text">Edit</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="boxOuter">
                                                    {/* <img src={roundIcon} className="roundImg" /> */}
                                                    <img src={plusOpen} className="openCloseImg" />
                                                    <div className="dataOuter">
                                                        <div className="data">
                                                            <div className="box">
                                                                <p className="count">2.</p>
                                                                <div className="details">
                                                                    <p className="detailsName">Drive closure of IDP Project</p>
                                                                    <div className="detailsInner">
                                                                        <p className="title">Tasks : </p>
                                                                        <p className="subTitle">1 Live, 3 Completed</p>
                                                                        <p className="dot">&nbsp;</p>
                                                                        <p className="title">Overdue:</p>
                                                                        <p className="subTitle">20 Days</p>
                                                                        <p className="dot">&nbsp;</p>
                                                                        <p className="title">Status:</p>
                                                                        {/* <p className="status">Work In Progress</p> */}
                                                                        <div className="selectOuter">
                                                                        <select>
                                                                            <option>Work In Progress</option>
                                                                            <option>Work In Progress</option>
                                                                            <option>Work In Progress</option>
                                                                            <option>Work In Progress</option>
                                                                            <option>Work In Progress</option>
                                                                        </select>
                                                                        </div>
                                                                        <p className="menu"></p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="action">
                                                                <div className="showNotes">
                                                                    <img src={notes} className="showNotesImg" />
                                                                    <p className="text">Show Notes</p>
                                                                </div>
                                                                <div className="edit">
                                                                    <img src={edit} className="editImg" />
                                                                    <p className="text">Edit</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="edit">
                                                            <p className="editTitle">Editing Goal “2”</p>
                                                            <div className="deleteGoalOuter">
                                                                <img src={deleteImg} className="deleteImg" />
                                                                <p className="deleteText">Delete this Goal</p>
                                                            </div>
                                                        </div>
                                                        <div className="extraData">
                                                            <div className="extraDataInner">
                                                                <p className="label">Goal name</p>
                                                                <p className="dataValue">Data Engineering - More complex Projects</p>
                                                                <label for="" class="form-label">Goal Name</label>
                                                                <input type="text" class="form-control" id="" aria-describedby="" placeholder="Data Engineering - More complex Projects" />
                                                            </div>
                                                            <div className="extraDataInner">
                                                                <p className="label">Goal Description</p>
                                                                <p className="dataValue">Gain an immersive understanding of the practices and skills needed to succeed in an entry-level project management role. Learn how to create effective project documentation and artifacts throughout the various phases of a project.</p>
                                                                <label for="" class="form-label">Goal Description</label>
                                                                <textarea type="text" class="form-control" id="" aria-describedby="" placeholder="Gain an immersive understanding of the practices and skills needed to succeed in an entry-level project management role. Learn how to create effective project documentation and artifacts throughout the various phases of a project." ></textarea>
                                                                <div id="" class="form-text">(256 characters Left)</div>
                                                            </div>
                                                            <div className="extraDataOuter">
                                                                <div className="extraDataInner">
                                                                    <p className="label">Start Date</p>
                                                                    <p className="dataValue">July 01, 2023</p>
                                                                    <label for="" class="form-label">Start Date</label>
                                                                    <input type="date" class="form-control" id="" aria-describedby="" placeholder="July 01, 2023" />
                                                                </div>
                                                                <div className="extraDataInner">
                                                                    <p className="label">Due Date</p>
                                                                    <p className="dataValue">May 30, 2024</p>
                                                                    <label for="" class="form-label">Due Date</label>
                                                                    <input type="date" class="form-control" id="" aria-describedby="" placeholder="May 30, 2024" />
                                                                </div>
                                                            </div>
                                                            <div className="extraDataOuter">
                                                                <div className="extraDataInner">
                                                                    <p className="label">Status</p>
                                                                    <p className="status">Work In Progress</p>
                                                                    <label for="" class="form-label">Status</label>
                                                                    <select class="form-select" aria-label="Default select example">
                                                                        <option selected>Work In Progress</option>
                                                                        <option value="1">Work In Progress</option>
                                                                        <option value="2">Work In Progress</option>
                                                                        <option value="3">Work In Progress</option>
                                                                    </select>
                                                                </div>
                                                                <div className="extraDataInner">&nbsp;</div>
                                                            </div>
                                                        </div>
                                                        <div className="moreData">
                                                            <hr className="dividerLine"></hr>
                                                            <div className="moreDataInner opentab">
                                                                <img src={plusOpen} className="plusOpen" />
                                                                <img src={minusClose} className="minusClose" />
                                                                <div className="dataTitleOuterOne">
                                                                    <div className="dataTitleOuter">
                                                                        <p className="dataTitle">Tasks</p>
                                                                        <p className="dataSubTitle">0 Live, 0 Completed</p>
                                                                    </div>
                                                                    <div className="tabs2">
                                                                        <div className="tabs-block tabs3Block">
                                                                            <div className={`tab ${activeTab3 === "Task1" ? "active" : ""}`} onClick={() => setActiveTab3("Task1")}>Task1</div>
                                                                            <div className={`tab ${activeTab3 === "AddMoreTask" ? "active" : ""}`} onClick={() => setActiveTab3("AddMoreTask")}>Add More Task</div>
                                                                        </div>
                                                                        {
                                                                            activeTab3 === "Task1" && 
                                                                            <div className="task1">
                                                                                <div className="extraData">
                                                                                    <div className="extraDataInner">
                                                                                        <label for="" class="form-label">Task Name</label>
                                                                                        <input type="text" class="form-control" id="" aria-describedby="" placeholder="Project Management Chapter 1" />
                                                                                    </div>
                                                                                    <div className="extraDataOuter">
                                                                                        <div className="extraDataInner">
                                                                                            <label for="" class="form-label">Start Date</label>
                                                                                            <input type="date" class="form-control" id="" aria-describedby="" placeholder="July 01, 2023" />
                                                                                        </div>
                                                                                        <div className="extraDataInner">
                                                                                            <label for="" class="form-label">Due Date</label>
                                                                                            <input type="date" class="form-control" id="" aria-describedby="" placeholder="May 30, 2024" />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="extraDataOuter">
                                                                                        <div className="extraDataInner">
                                                                                            <label for="" class="form-label">Status</label>
                                                                                            <select class="form-select" aria-label="Default select example">
                                                                                                <option selected>Yet to Start</option>
                                                                                                <option value="1">Yet to Start</option>
                                                                                                <option value="2">Yet to Start</option>
                                                                                                <option value="3">Yet to Start</option>
                                                                                            </select>
                                                                                        </div>
                                                                                        <div className="extraDataInner">&nbsp;</div>
                                                                                    </div>
                                                                                    <div className="radioBtn">
                                                                                        <p className="radio-title">Is this Task a "Course" or "Mentorship" ?</p>
                                                                                        <div class="radiobuttons">
                                                                                            <div class="rdio rdio-primary radio-inline"> 
                                                                                                <input name="radio" value="1" id="radio1" type="radio" checked />
                                                                                                <label for="radio1">No</label>
                                                                                            </div>
                                                                                            <div class="rdio rdio-primary radio-inline">
                                                                                                <input name="radio" value="2" id="radio2" type="radio" />
                                                                                                <label for="radio2">Yes</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="radioBtn">
                                                                                        <p className="radio-title">Task Type</p>
                                                                                        <div class="radiobuttons">
                                                                                            <div class="rdio rdio-primary radio-inline"> 
                                                                                                <input name="radio" value="11" id="radio11" type="radio" checked />
                                                                                                <label for="radio11">Course</label>
                                                                                            </div>
                                                                                            <div class="rdio rdio-primary radio-inline">
                                                                                                <input name="radio" value="22" id="radio22" type="radio" />
                                                                                                <label for="radio22">Mentorship</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="form">
                                                                                        <div class="formInner">
                                                                                            <label for="" class="form-label">Goal Name</label>
                                                                                            <input type="text" class="form-control" id="" aria-describedby="" />
                                                                                        </div>
                                                                                        <div class="formInner">
                                                                                            <label for="" class="form-label">Goal Description</label>
                                                                                            <textarea type="text" class="form-control" id="" aria-describedby="" ></textarea>
                                                                                            <div id="" class="form-text">(256 characters Left)</div>
                                                                                        </div>

                                                                                        <div className="formOuter">
                                                                                            <p className="formTitle">Mentor / Supervisor Details</p>
                                                                                            <div className="formInnerOne">
                                                                                                <div class="formInner">
                                                                                                    <label for="" class="form-label">Employee ID</label>
                                                                                                    <input type="text" class="form-control" id="" aria-describedby="" />
                                                                                                </div>
                                                                                                <div class="formInner">
                                                                                                    <label for="" class="form-label">Name</label>
                                                                                                    <input type="text" class="form-control" id="" aria-describedby="" />
                                                                                                </div>
                                                                                                <div class="formInner">
                                                                                                    <label for="" class="form-label">Designation</label>
                                                                                                    <input type="text" class="form-control" id="" aria-describedby="" />
                                                                                                </div>
                                                                                                <div class="formInner">
                                                                                                    <label for="" class="form-label">Department</label>
                                                                                                    <input type="text" class="form-control" id="" aria-describedby="" />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    
                                                                                    <div className="radioBtn">
                                                                                        <p className="radio-title">Learning Through Assistance Programs</p>
                                                                                        <div class="radiobuttons">
                                                                                            <div class="rdio rdio-primary radio-inline"> 
                                                                                                <input name="radio" value="11" id="radio11" type="radio" checked />
                                                                                                <label for="radio11">Yes</label>
                                                                                            </div>
                                                                                            <div class="rdio rdio-primary radio-inline">
                                                                                                <input name="radio" value="22" id="radio22" type="radio" />
                                                                                                <label for="radio22">No</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="btnOuterMain">
                                                                                        <div className="deleteGoalOuter">
                                                                                            <img src={deleteImg} className="deleteImg" />
                                                                                            <p className="deleteText">Delete this Goal</p>
                                                                                        </div>
                                                                                        <div className="btnOuter">
                                                                                            <button className="cancelBtn">Cancel</button>
                                                                                            <button className="saveBtn">Update Goal</button>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        }
                                                                        {
                                                                            activeTab3 === "AddMoreTask" && 
                                                                            <div className="addMoreTask">
                                                                                addMoreTask
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                    <div className="extraData">
                                                                        <div className="extraDataInner">
                                                                            <p className="label">Task Name</p>
                                                                            <p className="dataValue">Project Management Chapter 1</p>
                                                                        </div>
                                                                        <div className="extraDataOuter">
                                                                            <div className="extraDataInner">
                                                                                <p className="label">Start Date</p>
                                                                                <p className="dataValue">July 01, 2023</p>
                                                                            </div>
                                                                            <div className="extraDataInner">
                                                                                <p className="label">Due Date</p>
                                                                                <p className="dataValue">May 30, 2024</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="extraDataOuter">
                                                                            <div className="extraDataInner">
                                                                                <p className="label">Status</p>
                                                                                <p className="status yetToStart">Yet to start</p>
                                                                                <p className="status completed">Completed</p>
                                                                            </div>
                                                                            <div className="extraDataInner">
                                                                                <p className="label">Is this Task a "Course" or "Mentorship" ?</p>
                                                                                <p className="dataValue">No</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="extraDataInner">
                                                                            <p className="label">Course Link</p>
                                                                            <p className="dataValue">https://www.coursera.org/learn/python-for-application...</p>
                                                                        </div>
                                                                        <div className="extraDataInner">
                                                                            <p className="label">Course Details</p>
                                                                            <p className="dataValue">Gain an immersive understanding of the practices and skills needed to succeed in an entry-level project management role. Learn how to create effective project documentation and artifacts throughout the various phases of a project. Gain an immersive understanding of the practices and skills needed to succeed in an entry-level project management role. Learn how to create effective project documentation and artifacts throughout the various phases of a project.</p>
                                                                        </div>
                                                                        <div className="extraDataInner">
                                                                            <p className="label">Through Learning Assistance Programs</p>
                                                                            <p className="dataValue">Yes</p>
                                                                        </div>
                                                                        <div className="extraDataOuter">
                                                                            <div className="extraDataInner">
                                                                                <p className="label">Mentor / Supervisor Employee ID</p>
                                                                                <p className="dataValue">EMP5545454</p>
                                                                            </div>
                                                                            <div className="extraDataInner">
                                                                                <p className="label">Mentor / Supervisor Name</p>
                                                                                <p className="dataValue">EMP5545454</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="extraDataOuter">
                                                                            <div className="extraDataInner">
                                                                                <p className="label">Mentor / Supervisor Designation</p>
                                                                                <p className="dataValue">Sr. Architect</p>
                                                                            </div>
                                                                            <div className="extraDataInner">
                                                                                <p className="label">Mentor / Supervisor Department</p>
                                                                                <p className="dataValue">Dept: Engineering Development</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    
                                                                </div>
                                                            </div>
                                                            <hr className="dividerLine"></hr>
                                                            <div className="moreDataInner">
                                                                <img src={plusOpen} className="" />
                                                                <div className="dataTitleOuterOne">
                                                                    <div className="dataTitleOuter">
                                                                        <div className="dataTitleOuterTwo">
                                                                            <p className="dataTitle">Link to Competency</p>
                                                                            <img src={infoCircular} className="infoCircular" />
                                                                        </div>
                                                                        <p className="dataSubTitle">0 Competencies</p>
                                                                    </div>
                                                                    <div className="form">
                                                                        <div className="datePicker">
                                                                            <div class="formInner">
                                                                                <label for="" class="form-label">Linkage to Competency</label>
                                                                                <select class="form-select" aria-label="Default select example">
                                                                                    <option selected>Select</option>
                                                                                    <option value="1">Select</option>
                                                                                    <option value="2">Select</option>
                                                                                    <option value="3">Select</option>
                                                                                </select>
                                                                                <div className="selected">
                                                                                    <p className="option">
                                                                                        Coding
                                                                                        <img src={crossImg} className="infoCircular" />
                                                                                    </p>
                                                                                    <p className="option">
                                                                                        Design
                                                                                        <img src={crossImg} className="infoCircular" />
                                                                                    </p>
                                                                                    <p className="option">
                                                                                        Understanding of Systems
                                                                                        <img src={crossImg} className="infoCircular" />
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                            <div class="formInner">&nbsp;</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <hr className="dividerLine"></hr>
                                                            <div className="moreDataInner">
                                                                <img src={plusOpen} className="" />
                                                                <div className="dataTitleOuterOne">
                                                                    <div className="dataTitleOuter">
                                                                        <p className="dataTitle">Link to Aspiration</p>
                                                                        <p className="dataSubTitle">0 Aspiration</p>
                                                                    </div>
                                                                    <div className="form">
                                                                        <div className="datePicker">
                                                                            <div class="formInner">
                                                                                <label for="" class="form-label">Aspiration</label>
                                                                                <select class="form-select" aria-label="Default select example">
                                                                                    <option selected>Select</option>
                                                                                    <option value="1">Select</option>
                                                                                    <option value="2">Select</option>
                                                                                    <option value="3">Select</option>
                                                                                </select>
                                                                                <div className="selected">
                                                                                    <p className="option">
                                                                                        Work on lucy (data mart) tech stack
                                                                                        <img src={crossImg} className="infoCircular" />
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                            <div class="formInner">&nbsp;</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <hr className="dividerLine"></hr>
                                                        </div>
                                                        <div className="btnOuter">
                                                            <button className="cancelBtn">Cancel</button>
                                                            <button className="saveBtn">Update Goal</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="boxOuter">
                                                    <img src={plusOpen} className="openCloseImg" />
                                                    <div className="dataOuter">
                                                        <div className="data">
                                                            <div className="box">
                                                                <p className="count">3.</p>
                                                                <div className="details">
                                                                    <p className="detailsName">Drive closure of IDP Project</p>
                                                                    <div className="detailsInner">
                                                                        <p className="title">Tasks : </p>
                                                                        <p className="subTitle">2 Completed</p>
                                                                        <p className="dot">&nbsp;</p>
                                                                        <p className="title">Due Date:</p>
                                                                        <p className="subTitle">December 15, 2022</p>
                                                                        <p className="dot">&nbsp;</p>
                                                                        <p className="title">Status:</p>
                                                                        {/* <p className="status">Work In Progress</p> */}
                                                                        <div className="selectOuter">
                                                                        <select>
                                                                            <option>Work In Progress</option>
                                                                            <option>Work In Progress</option>
                                                                            <option>Work In Progress</option>
                                                                            <option>Work In Progress</option>
                                                                            <option>Work In Progress</option>
                                                                        </select>
                                                                        </div>
                                                                        <p className="menu"></p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="action">
                                                                <div className="showNotes">
                                                                    <img src={notes} className="showNotesImg" />
                                                                    <p className="text">Show Notes</p>
                                                                </div>
                                                                <div className="edit disabled">
                                                                    <img src={edit} className="editImg" />
                                                                    <img src={editDisabled} className="editDisabledImg" />
                                                                    <p className="text">Edit</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {
                                            activeTab2 === "Completed" && 
                                            <div className="completed">
                                                Completed
                                            </div>
                                        }
                                        {
                                            activeTab2 === "Archived" && 
                                            <div className="archived">
                                                Archived
                                            </div>
                                        }
                                    </div>
                                </div> 
                                <div className="block">
                                    <div className="titleOuter">
                                        <p className="title">1. Output / Development Goal</p>
                                    </div>
                                    <div className="fieldOuter">
                                        <img src={roundIcon} className="roundImg" />
                                        <div className="fieldsOuter">
                                            <div className="fields">
                                                <p className="fieldTitle">Add Output / Goal</p>
                                                <div className="form">
                                                    <div class="formInner">
                                                        <label for="" class="form-label">Goal Name</label>
                                                        <input type="text" class="form-control" id="" aria-describedby="" />
                                                    </div>
                                                    <div class="formInner">
                                                        <label for="" class="form-label">Goal Description</label>
                                                        <textarea type="text" class="form-control" id="" aria-describedby="" ></textarea>
                                                        <div id="" class="form-text">(256 characters Left)</div>
                                                    </div>
                                                    <div className="datePicker">
                                                        <div class="formInner">
                                                            <label for="" class="form-label">Start Date</label>
                                                            <input type="date" class="form-control" id="" aria-describedby="" />
                                                        </div>
                                                        <div class="formInner">
                                                            <label for="" class="form-label">Due Date</label>
                                                            <input type="date" class="form-control" id="" aria-describedby="" />
                                                        </div>
                                                    </div>
                                                    <div className="datePicker">
                                                        <div class="formInner">
                                                            <label for="" class="form-label">Status</label>
                                                            <select class="form-select" aria-label="Default select example">
                                                                <option selected>Work In Progress</option>
                                                                <option value="1">Work In Progress</option>
                                                                <option value="2">Work In Progress</option>
                                                                <option value="3">Work In Progress</option>
                                                            </select>
                                                        </div>
                                                        <div class="formInner">&nbsp;</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="moreData">
                                                <hr className="dividerLine"></hr>
                                                <div className="data">
                                                    <img src={plusOpen} className="" />
                                                    <p className="dataTitle">Tasks</p>
                                                </div>
                                                <hr className="dividerLine"></hr>
                                                <div className="data">
                                                    <img src={plusOpen} className="" />
                                                    <p className="dataTitle">Link to Competency</p>
                                                </div>
                                                <hr className="dividerLine"></hr>
                                                <div className="data">
                                                    <img src={plusOpen} className="" />
                                                    <p className="dataTitle">Link to Aspiration</p>
                                                </div>
                                                <hr className="dividerLine"></hr>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="btnOuter">
                                        <button className="cancelBtn">Cancel</button>
                                        <button className="saveBtn">Save Goal</button>
                                    </div>
                                </div>
                                <div className="block">
                                    <div className="titleOuter">
                                        <p className="title">2. Reflection</p>
                                        <div className="selectOuter">
                                            <select>
                                                <option>Q1 : 2024</option>
                                                <option>Q1 : 2024</option>
                                                <option>Q1 : 2024</option>
                                                <option>Q1 : 2024</option>
                                                <option>Q1 : 2024</option>
                                            </select>
                                        </div>
                                    </div> 
                                    <div className="info">
                                        <img src={info} className="infoImg" />
                                        <p className="infoText">Check-In is NOT LIVE, You can view earlier notes.</p>
                                    </div>
                                    <div className="questionOuter">
                                        <div className="questionList">
                                            <p className="question  active">What is going well / went well ?</p>
                                            <p className="question">What can be improved ?</p>
                                            <p className="question">Share your aspirations/new skills (you want to work on structurally)</p>
                                        </div>
                                        <div className="answers">
                                            <div className="comment">
                                                <div className="commentOuter">
                                                    <img src={userImage} className="userImage" />
                                                    <div className="commentInner">
                                                        <p className="name">Sayed Adil Hussain !!</p>
                                                        <div className="date">
                                                            <p className="text">You</p>
                                                            <p className="dot">&nbsp;</p>
                                                            <p className="text">July 06, 2024</p>
                                                        </div>
                                                        <p className="desc">Earn career credentials from industry leaders that demonstrate your expertise. </p>
                                                    </div>
                                                </div>
                                                <div className="commentOuter">
                                                    <img src={userImage} className="userImage" />
                                                    <div className="commentInner">
                                                        <p className="name">Puneet Khandelwal</p>
                                                        <div className="date">
                                                            <p className="text">Manager</p>
                                                            <p className="dot">&nbsp;</p>
                                                            <p className="text">July 06, 2024</p>
                                                        </div>
                                                        <p className="desc">Earn career credentials from industry leaders that demonstrate your expertise. </p>
                                                    </div>
                                                </div>
                                                <div className="commentOuter">
                                                    <img src={userImage} className="userImage" />
                                                    <div className="commentInner">
                                                        <p className="name">Sayed Adil Hussain !!</p>
                                                        <div className="date">
                                                            <p className="text">You</p>
                                                            <p className="dot">&nbsp;</p>
                                                            <p className="text">July 06, 2024</p>
                                                        </div>
                                                        <p className="desc">Earn career credentials from industry leaders that demonstrate your expertise. </p>
                                                    </div>
                                                </div>
                                                <div className="commentOuter">
                                                    <img src={userImage} className="userImage" />
                                                    <div className="commentInner">
                                                        <p className="name">Puneet Khandelwal</p>
                                                        <div className="date">
                                                            <p className="text">Manager</p>
                                                            <p className="dot">&nbsp;</p>
                                                            <p className="text">July 06, 2024</p>
                                                        </div>
                                                        <p className="desc">Earn career credentials from industry leaders that demonstrate your expertise. </p>
                                                    </div>
                                                </div>
                                                <div className="commentOuter">
                                                    <img src={userImage} className="userImage" />
                                                    <div className="commentInner">
                                                        <p className="name">Sayed Adil Hussain !!</p>
                                                        <div className="date">
                                                            <p className="text">You</p>
                                                            <p className="dot">&nbsp;</p>
                                                            <p className="text">July 06, 2024</p>
                                                        </div>
                                                        <p className="desc">Earn career credentials from industry leaders that demonstrate your expertise. </p>
                                                    </div>
                                                </div>
                                                <div className="commentOuter">
                                                    <img src={userImage} className="userImage" />
                                                    <div className="commentInner">
                                                        <p className="name">Puneet Khandelwal</p>
                                                        <div className="date">
                                                            <p className="text">Manager</p>
                                                            <p className="dot">&nbsp;</p>
                                                            <p className="text">July 06, 2024</p>
                                                        </div>
                                                        <p className="desc">Earn career credentials from industry leaders that demonstrate your expertise. </p>
                                                    </div>
                                                </div>
                                                <div className="commentOuter">
                                                    <img src={userImage} className="userImage" />
                                                    <div className="commentInner">
                                                        <p className="name">Sayed Adil Hussain !!</p>
                                                        <div className="date">
                                                            <p className="text">You</p>
                                                            <p className="dot">&nbsp;</p>
                                                            <p className="text">July 06, 2024</p>
                                                        </div>
                                                        <p className="desc">Earn career credentials from industry leaders that demonstrate your expertise. </p>
                                                    </div>
                                                </div>
                                                <div className="commentOuter">
                                                    <img src={userImage} className="userImage" />
                                                    <div className="commentInner">
                                                        <p className="name">Puneet Khandelwal</p>
                                                        <div className="date">
                                                            <p className="text">Manager</p>
                                                            <p className="dot">&nbsp;</p>
                                                            <p className="text">July 06, 2024</p>
                                                        </div>
                                                        <p className="desc">Earn career credentials from industry leaders that demonstrate your expertise. </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="notes">
                                <p className="title">Notes</p>
                                <div className="descOuter none">
                                    <img src={illustration} className="illustration" />
                                    <p className="desc">Please click any Output / Development Goal OR Reflection section to edit Notes</p>
                                </div>
                                <div className="notesOuter">
                                    <p className="notesTitle">Data Engineering - More complex Projects</p>
                                    <div className="notesInner">
                                        <div className="commentOuter">
                                            <img src={userImage} className="userImage" />
                                            <div className="commentInner">
                                                <p className="name">Sayed Adil Hussain !!</p>
                                                <div className="date">
                                                    <p className="text">You</p>
                                                    <p className="dot">&nbsp;</p>
                                                    <p className="text">July 06, 2024</p>
                                                </div>
                                                <p className="desc">Earn career credentials from industry leaders that demonstrate your expertise. </p>
                                            </div>
                                        </div>
                                        <div className="commentOuter">
                                            <img src={userImage} className="userImage" />
                                            <div className="commentInner">
                                                <p className="name">Sayed Adil Hussain !!</p>
                                                <div className="date">
                                                    <p className="text">You</p>
                                                    <p className="dot">&nbsp;</p>
                                                    <p className="text">July 06, 2024</p>
                                                </div>
                                                <p className="desc">Earn career credentials from industry leaders that demonstrate your expertise. </p>
                                            </div>
                                        </div>
                                        <div className="commentOuter">
                                            <img src={userImage} className="userImage" />
                                            <div className="commentInner">
                                                <p className="name">Sayed Adil Hussain !!</p>
                                                <div className="date">
                                                    <p className="text">You</p>
                                                    <p className="dot">&nbsp;</p>
                                                    <p className="text">July 06, 2024</p>
                                                </div>
                                                <p className="desc">Earn career credentials from industry leaders that demonstrate your expertise. </p>
                                            </div>
                                        </div>
                                        <div className="commentOuter">
                                            <img src={userImage} className="userImage" />
                                            <div className="commentInner">
                                                <p className="name">Sayed Adil Hussain !!</p>
                                                <div className="date">
                                                    <p className="text">You</p>
                                                    <p className="dot">&nbsp;</p>
                                                    <p className="text">July 06, 2024</p>
                                                </div>
                                                <p className="desc">Earn career credentials from industry leaders that demonstrate your expertise. </p>
                                            </div>
                                        </div>
                                        <div className="commentOuter">
                                            <img src={userImage} className="userImage" />
                                            <div className="commentInner">
                                                <p className="name">Sayed Adil Hussain !!</p>
                                                <div className="date">
                                                    <p className="text">You</p>
                                                    <p className="dot">&nbsp;</p>
                                                    <p className="text">July 06, 2024</p>
                                                </div>
                                                <p className="desc">Earn career credentials from industry leaders that demonstrate your expertise. </p>
                                            </div>
                                        </div>
                                        <div className="commentOuter">
                                            <img src={userImage} className="userImage" />
                                            <div className="commentInner">
                                                <p className="name">Sayed Adil Hussain !!</p>
                                                <div className="date">
                                                    <p className="text">You</p>
                                                    <p className="dot">&nbsp;</p>
                                                    <p className="text">July 06, 2024</p>
                                                </div>
                                                <p className="desc">Earn career credentials from industry leaders that demonstrate your expertise. </p>
                                            </div>
                                        </div>
                                        <div className="commentOuter">
                                            <img src={userImage} className="userImage" />
                                            <div className="commentInner">
                                                <p className="name">Sayed Adil Hussain !!</p>
                                                <div className="date">
                                                    <p className="text">You</p>
                                                    <p className="dot">&nbsp;</p>
                                                    <p className="text">July 06, 2024</p>
                                                </div>
                                                <p className="desc">Earn career credentials from industry leaders that demonstrate your expertise. </p>
                                            </div>
                                        </div>
                                        <div className="commentOuter">
                                            <img src={userImage} className="userImage" />
                                            <div className="commentInner">
                                                <p className="name">Sayed Adil Hussain !!</p>
                                                <div className="date">
                                                    <p className="text">You</p>
                                                    <p className="dot">&nbsp;</p>
                                                    <p className="text">July 06, 2024</p>
                                                </div>
                                                <p className="desc">Earn career credentials from industry leaders that demonstrate your expertise. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="msgSend">
                                    <input placeholder="Enter your comment" />
                                    <img src={send} className="send" />
                                </div>
                            </div>



                            <div className="ratingOuter none">
                                <div className="rating">
                                    <img src={minusClose} className="openCloseImg" />
                                    <div className="ratingInner">
                                        <p className="ratingTitle">1. <span className="name">Head - Business Finance</span><span className="subName">(Current Role)</span></p>
                                        <div className="detailsTwo">
                                            <div className="detailsTwoInner">
                                                <p className="title">Time in Role</p>
                                                <p className="subTitle">2 Years (Apr 2022 to Present)</p>
                                            </div>
                                            <div className="detailsTwoInner">
                                                <p className="title">Business Unit</p>
                                                <p className="subTitle">Corporate</p>
                                            </div>
                                            <div className="detailsTwoInner">
                                                <p className="title">Department</p>
                                                <p className="subTitle">HR Ops & Technology</p>
                                            </div>
                                            <div className="detailsTwoInner">
                                                <p className="title">Location</p>
                                                <p className="subTitle">Bengaluru Urban, Karnataka</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="rating">
                                    <img src={roundIcon} className="roundImg" />
                                    <div className="subRating">
                                        <p className="ratingSubTitle">Responsibilities</p>
                                        <div className="listOuter">
                                            <p className="listTitle">Financial Planning & Analysis (FP&A)</p>
                                            <ul className="list">
                                                <li>Create financial forecasts and budgets for the business unit.</li>
                                                <li>Analyze financial data to identify trends and risks.</li>
                                                <li>Develop long-term financial plans to support business growth.</li>
                                                <li>Partner with other departments (e.g., Sales, Operations) to ensure financial alignment with business goals.</li>
                                            </ul>
                                        </div>
                                        <div className="listOuter">
                                            <p className="listTitle">Financial Operations</p>
                                            <ul className="list">
                                                <li>Create financial forecasts and budgets for the business unit.</li>
                                                <li>Oversee financial reporting and ensure compliance with accounting standards.</li>
                                                <li>Develop and implement internal controls to safeguard financial assets.</li>
                                                <li>Manage a team of finance professionals (depending on the company size)</li>
                                            </ul>
                                        </div>
                                        <div className="listOuter">
                                            <p className="listTitle">Strategic Support</p>
                                            <ul className="list">
                                                <li>Provide financial insights and analysis to support business decisions.</li>
                                                <li>Evaluate investment opportunities and recommend courses of action.</li>
                                                <li>Manage relationships with banks and other financial institutions.</li>
                                                <li>Identify cost-saving opportunities and implement process improvements.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="rating">
                                    <img src={roundIcon} className="roundImg" />
                                    <div className="subRating">
                                        <p className="ratingSubTitle">Projects / Assignments</p>
                                        <div className="projectDetails">
                                            <div className="goal">
                                                <p className="goalTitle">Goal</p>
                                                <p className="goalDesc">Buliding HR Datamart as a long-term solution to cater to present & future analytcs needs of boh HR & Business</p>
                                            </div>
                                            <div className="desc">
                                                <p className="descTitle">Description</p>
                                                <p className="content">This is to mark a shift, <br></br>
                                                    From Excel sheets being currently stored on a SQL layer to feed Analytics dashboards, <br></br>
                                                    TO API Based real -time integration b/w Source &gt;&gt; through an Intermediate Layer &gt;&gt; to the BI-layer
                                                </p>
                                                <p className="content">It involves,</p>
                                                <p className="content">1. SAP HANA vs Lucy: Finalizing the platform for HR datamart.</p>
                                                <p className="content">2. SAP HANA vs Lucy: Finalizing the platform for HR datamart.</p>
                                                <p className="content">3. SAP HANA vs Lucy: Finalizing the platform for HR datamart.</p>
                                                <p className="content">4. SAP HANA vs Lucy: Finalizing the platform for HR datamart.</p>
                                                <p className="content">5. SAP HANA vs Lucy: Finalizing the platform for HR datamart.</p>
                                            </div>
                                            <div className="due">
                                                <div className="dueDate">
                                                    <p className="title">Start Date</p>
                                                    <p className="date">24 Apr 2024</p>
                                                </div>
                                                <div className="dueDate">
                                                    <p className="title">End Date</p>
                                                    <p className="date">24 Apr 2024</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                            <div className="ratingOuter none">
                                <div className="rating">
                                    <img src={plusOpen} className="openCloseImg" />
                                    <div className="ratingInner">
                                        <p className="ratingTitle">2. <span className="name">Associate Head - Business Finance</span></p>
                                    </div>
                                </div>
                            </div>
                            <div className="ratingOuter none">
                                <div className="rating">
                                    <img src={plusOpen} className="openCloseImg" />
                                    <div className="ratingInner">
                                        <p className="ratingTitle">3. <span className="name">Associate Head - Business Finance</span></p>
                                    </div>
                                </div>
                            </div>
                            <hr className="dividerLine none"></hr>
                        </div>
                    }

                    {
                        activeTab === "CheckIns" && 
                        <div className="checkIns">
                            <div className="head">
                                <div className="headOuter">
                                    <p className="title">Your Team members</p>
                                    <p className="subTitle">In publishing and graphic design, Lorem ipsum is a placeholder text</p>
                                </div>
                                <select class="form-select" aria-label="Default select example">
                                    <option selected>Select Member</option>
                                    <option value="1">Select Member</option>
                                    <option value="2">Select Member</option>
                                    <option value="3">Select Member</option>
                                </select>
                            </div>
                        </div>
                    }
                    
                </div>
                <div className="profile-details">
                    <div className="workflows">
                        <p className="title">Workflows</p>
                        <div className="rating">
                            <div className="ratingInner">
                                <table>
                                    <thead>
                                        <tr>
                                            <th className="right"></th>
                                            <th># of LIVE Workflows</th>
                                            <th># of Completed Workflows</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="bg right">For your Action</td>
                                            <td>                                     
                                                <span className="tableArrowCorcle">3  <img src={circleArrow} className="showNotesImg" /></span>
                                            </td>
                                            <td>
                                                <span className="tableArrowCorcle">1 <img src={circleArrow} className="showNotesImg" /></span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="bg right">For your Visibility : Pending on others</td>
                                            <td>
                                                <span className="tableArrowCorcle">1 <img src={circleArrow} className="showNotesImg" /></span>
                                            </td>
                                            <td>
                                                <span className="tableArrowCorcle">4 <img src={circleArrow} className="showNotesImg" /></span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default EmployeePlan;