import { createBrowserHistory } from 'history';
import { combineReducers } from "redux";
import logInReducer from "../redux/login/reducers";
import empDataReducer from "../redux/employeeData/reducers";
export const history = createBrowserHistory();
const createRootReducer = () =>
  combineReducers({   
    user: logInReducer,
    empData: empDataReducer,
  });

export default createRootReducer;
