import { toast } from 'react-toastify';
import { call, put, takeLatest } from 'redux-saga/effects';
import api from '../../api/index';
import { actions, types } from './actions';
import { BsFillCheckCircleFill } from "react-icons/bs";
import { RiErrorWarningFill } from "react-icons/ri";

const LogInRequest = function* LogInRequest({ payload }) {

    // console.log("payload",payload)
    if(payload?.username === "logInThroughPopupWindow"){
      
            if (payload?.password) {
                yield put(actions.LogInRequestSuccess({id_token:payload?.password}));
                // toast.success(<div className='flex flex-row'><BsFillCheckCircleFill style={{ width: "2rem", height: "2rem" }} />&nbsp;&nbsp;feched succesfully</div>);
            } else {

                // toast.error(data.message);
                yield put(actions.LogInRequestFailed());
            }
       

    } else {    
        try {
            const { data } = yield call(api.authenticateUser, payload,);
            if (data) {
                yield put(actions.LogInRequestSuccess(data));
                // toast.success(<div className='flex flex-row'><BsFillCheckCircleFill style={{ width: "2rem", height: "2rem" }} />&nbsp;&nbsp;feched succesfully</div>);
            } else {
                // toast.error(data.message);
                yield put(actions.LogInRequestFailed());
            }
        } catch (err) {
            toast.error(<div className='flex flex-row'><RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />&nbsp;&nbsp;{err ? err.detail : 'Something went wrong Please try again'}</div>);
            // console.log("err",err);
            yield put(actions.LogInRequestFailed());
        }
}
};
const UserRequest = function* UserRequest({ payload }) {
    try {
        const { data } = yield call(api.lookUpAccount, payload);
        if (data) {
            yield put(actions.UserRequestSuccess(data));
            // toast.success(<div className='flex flex-row'><BsFillCheckCircleFill style={{ width: "2rem", height: "2rem" }} />&nbsp;&nbsp;feched succesfully</div>);
        } else {
            // toast.error(data.message);
            yield put(actions.UserRequestFailed());
            yield put(actions.clearLogInToken());
        }
    } catch (err) {
        toast.error(<div className='flex flex-row'><RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />&nbsp;&nbsp;{err ? err.message : 'Something went wrong Please try again'}</div>);
        yield put(actions.UserRequestFailed());
        yield put(actions.clearLogInToken());
    }
};

export default function* sagas() {
    yield takeLatest(types.LOGIN_REQUEST, LogInRequest);
    yield takeLatest(types.USER_REQUEST, UserRequest);
}