import { all, fork, select, takeEvery } from "redux-saga/effects";
import logInSagas from "../redux/login/sagas";
import empDataSagas from "../redux/employeeData/sagas";

function* watchAndLog() {
  yield takeEvery("*", function* logger(action) {
    const state = yield select();
    console.debug("action", action);
    console.debug("state after", state);
  });
}

export default function* root() {
  yield all([
    fork(watchAndLog),
    fork(logInSagas),
    fork(empDataSagas),
  ]);
};
